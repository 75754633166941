export let translationUz = {
  connection: 'Ulanish',
  tariff: 'Tariflar',
  workingMethod: 'Ishlash usuli',
  enter: 'Kirish',
  registration: 'Roʻyxatdan oʻtish',
  systemElectronicDocument: 'Elektron hujjat aylanish tizimi',
  kiberInformation:
    "Kiber - bu schyot-fakturalar va boshqa buxgalteriya hujjatlari uchun tezkor, sodda va arzon elektron hujjat aylanishi xizmati. Biz eng yuqori sifatli xizmatni taqdim etishdan va ishingizni soddalashtirishdan mamnunmiz. Siz bizni tanqid qilishingiz biz uchun g'amxo'rligingizdir ",
  kiberMethod: 'Kiberda ishlash usuli ',
  kiberSystem: 'Kiber tizimi bilan',
  systemInformation:
    "U bilan ishlashni oson o'zlashtira olasiz, chunki  oson va tushunarli interfeys.",
  kiberQ_A: 'Kiber — sovollar va jovoblar',
  q_aInformation:
    "Savollaringiz bo'lsa, qo'shimcha ravishda Telegramdagi `Kiber - savol-javoblar` guruhiga kirishingiz mumkin.",
  IndividualConsultations: 'Alohida konsultatsiya',
  IndividualConsultationsInformation:
    '“Pulli” tarifidan foydalanuvchilar uchun esa mutaxassislarimizning individual konsultatsiyasi mavjud.',
  shareDocumentsPhone: 'Telefoningizdagi hujjatlarni ulashing',
  shareDocumentsPhoneInformation:
    "Tez orada siz Kiber mobil ilovasi yordamida elektron hujjatlarni almashishingiz mumkin bo'ladi. Bu sizning biznesingizni nafaqat osonlashtiradi, balki yanada yoqimli va chiroyli qiladi.",
  loginWorld: 'Dunyoning istalgan nuqtasidan tizimga kirish!',
  downloadMobile:
    "Mobil ilovani yuklab oling va qog'oz muommolardan xalos bo'ling!",
  electronicDocument:
    '“KIBER.UZ” elektron hujjat almashinuv dasturi (1.0 versiya)',
  system: 'Tizim',
  support: "Qo'llab-quvvatlash xizmati",
  faq: 'Indiviual sovollar',
  UsersManual: "Foydalanuvchi uchun qo'llanma",
  feedback: 'Qayta aloqa',
  followUs: 'Bizni kuzatib boring',
  servicesLicensed: 'Xizmatlar litsenziyalangan, tovarlar sertifikatlangan',
  loginSystem: 'Tizimga kirish',
  chooseKey: 'Kalitni tanlang',
  whithKey: 'Kalit bilan',
  personalArea: 'Shaxsiy kabinet',
  Statistics: 'Statistika',
  userData: "Foydalanuvchi ma'lumotlari",
  notification: 'Bildirishnoma',
  editFoto: "Rasmni o'zgartirish",
  userPosition: 'Foydalanuvchi pozitsiyasi',
  editData: "Ma'lumotlarni tahrirlash",
  seve: 'Saqlash',
  tin: 'STIR',
  tinTwo: 'Stir',
  position: 'Lavozim',
  bankAccount: 'Bank hisob raqami',
  mfi: 'MFI',
  phoneNumber: 'Telefon raqami',
  mail: 'Pochta',
  firstLastName: 'Ism va familiya',
  login: 'Kirish',
  parol: 'Parol',
  editingProfilePhoto: 'Profil fotosuratini o`zgartirish',
  chooseAnother: 'Boshqasini tanlang',
  downloadPDF: 'PDF yuklab olish',
  accept: 'Qabul qilmoq',
  cansel: 'Bekor qilish',
  decline: 'Bekorqilish',
  myGoods: 'Mening tovarlarim (xizmatlarim)',
  enterNameCode: 'Mahsulot (xizmat) nomi yoki kodini kiriting',
  add: "Qo'shish",
  classifierCode: 'Klassifikator kodi',
  classifierName: 'Klassifikator nomi',
  saved: 'Saqlangan',
  documentType: 'Hujjat turi',
  dataCreation: 'Yaratilgan sana',
  outgoingDocuments: 'Chiqish hujjatlari',
  incomingDocuments: 'Kiruvchi hujjatlar',
  powerDocuments: 'Ishonchnoma hujjatlari',
  invoices: 'Hisob-fakturalar',
  acts: 'Dalolatnomalar',
  contract: 'Shartnomalar',
  empowerment: 'Ishonchnomalar',
  filter: 'Filtr',
  document: 'Hujjat',
  documents: 'Hujjatlar',

  standard: 'Standart',
  additional: "Qo'shimcha",
  additionalExp: "Qo'shimcha(Xarajatlarni Qoplash)",
  compensationExp: 'Xarajatlarni Qoplash',
  noPayment: "To'lovsiz",
  corrected: 'Tuzatuvchi',
  correctedExp: 'Tuzatuvchi(Xarajatlarni Qoplash)',
  ttu: `Yo'l varaqasi`,
  checkedActs: 'Solishtirma dalolatnomalar',

  phisFace: 'Jismony shaxsga',
  eksportServiceFrom: "Xizmatlar eksporti (O'zr hududidan tashqarida)",
  importService: 'Xizmatlar importi',
  financeService: 'Moliyaviy xizmatlar',
  belowMarket: 'Bozor narxidan past narxda realizasiya',
  belowTax: 'Bojxona narxidan past narxda realizasiya',
  eskportServiceIn: "Xizmatlar eksporti (O'zR hududida)",
  realizationGov: "Davlat sekreti bilan bo'gliq realizasiya",
  otherIncome: 'Boshqa daromadlar',
  recycleAksiz: 'Aksiz mahsulotlarni qayta ishlashga topshirish',
  reuseAksiz: "Aksiz mahsulotlarni o'z ehtiyoji uchun foydalanishi",
  priceDifferenceAksiz: "Aksiz mahsulotlar narxi va tannarxi o'rtasidagi farq",
  destroyAksiz: "Aksiz mahsulotlar yo'qotilishi buzilishi",

  newTransportNo: 'Yangi tovar-transport yukxati',
  transportNo: 'Tovar-transport yukxati raqami',
  date: 'sanasi',
  carriageType: 'Tashish turi',
  contractNo: 'Shartnoma raqami',
  roadNo: 'Yoʻl varaqasi raqami',
  transportType: 'Transport turi',
  fromCtoC: 'omborxonadan omborxonagacha',
  fromStoB: 'sotuvchidan xaridorgacha',
  car: 'Avtomobil transportida tashish',
  train: 'Temir yoʻl transportida tashish',

  type: 'tur',
  carGovNo: 'Avtomobil davlat raqami',
  trailer: 'tirkama',
  semiTrailer: 'yarim tirkama',
  govNo: 'Dav. raqami',
  shiftForm: 'Smena bo’yicha yig’ma',
  driverName: 'Haydovchi FISh',
  roadList: 'Yoʻl Varaqasi',
  transport: 'Transport',

  dealer: 'Yukni tashuvchi',
  customer: 'Buyurtmachi',
  specialNotes: 'Maxsus Belgilar',
  productAdded: 'Maxsulot kiritildi',
  sender: 'Yukni joʻnatuvchi',
  firstAddressTo: 'Yuklash manzili 1',
  secondAddressTo: 'Yuklash manzili 2',
  otherAddress: 'Boshqa manzil',
  submitted: ' Topshirdi',
  getter: 'Yukni oluvchi',
  firstAddressDrop: 'Tushirish manzili 1',
  secondAddressDrop: 'Tushirish manzili 2',
  driver: 'Haydovchi',
  recieved: 'Qabul qildi',
  distance: 'Tashish oraligʻi',
  overall: 'jami',
  inCity: 'shaharda',
  filial: 'Filial',
  docActive: ' Guvohnoma faol',
  docNotActive: ' Guvohnoma faol emas',
  docTempNotActive: ' Guvohnoma vaqtinchalik faol emas',

  waybill: "Yo'l varaqasi",
  trailerType: 'Tirkama turi',
  tarifs: 'Tariflar',
  chooseTarif: 'Tarif Tanlash',
  productsWithoutVat: 'QQSsiz mahsulot',
  productsWithZeroVat: 'Mahsulot/xizmatlar (QQS stavka 0%)',
  companiesSeller: 'QQSsiz yoki QQS stavkasi 0%da sotuvchi tashkilotlar',
  companiesBuyer: 'QQSsiz yoki QQS stavkasi 0%da xaridor tashkilotlar',
  productsNotIncluded: 'Hisobga olinmaydigan mahsulotlar',
  compensations: 'Qoplanadigan xizmatlar',
  productId: 'T/R',
  catalogCode: 'MXIK',
  catalogName: "Klassifikator bo'yicha nomi",
  lgotaId: 'Imtiyoz ID (eski)',
  newLgotaId: 'Imtiyoz ID (yangi)',
  lgotaName: 'Imtiyoz mazmuni',
  tasnifName: 'Kategoriya nomi',
  tasnifCode: 'MXIK',
  nameOfCompany: 'Korxona nomi',
  dateStart: 'Imtiyoz boshlanishi',
  dateEnd: 'Imtiyoz tugashi',
  tinCompany: 'Korxona STIRi',
  number: 'Raqam',
  sum: "So'm",
  treaty: 'Shartnoma',
  clear: 'Tozalash',
  apply: 'Tasdiqlash',
  status: 'Maqom',
  counterparty: 'Kontragent',
  numberData: 'Raqam va sana',
  subscribe: 'Imzolash',
  createContract: 'Yangi shartnomani yaratish',
  tinPinfl: 'STIR/JShShIR',
  LotNo: 'Lot raqami',
  name: 'Nom',
  fizTin: 'Jismoniy shaxsning JShShIR',
  fio: 'FISh',
  mobile: 'Uyali telefon raqami',
  workPhone: 'Telefon',
  address: 'Manzil',
  bankId: 'MFO, SWIFT va boshqa',
  account: 'Hisob raqami',
  oked: 'IFUT',
  addProduct: "Yangi mahsulot qo'shish",
  total: 'Jami',
  only: 'Jami',
  exciseSum: 'Aktsiz, miqdor',
  exciseRate: 'aktsiz, stavka',
  vatSum: 'QQS, Summa',
  vat: 'QQS',
  deliveryCost: 'Yetkazib berish narxi',
  price: 'Narxi',
  qty: 'Miqdor',
  unitMeasurements: 'O‘lchash birligi',
  barCode: 'Tovarlar/xizmatlar shtrix-kodi',
  productIdentification: ' Mahsulot identifikatsiya kodi (xizmat)',
  nameGoods: 'Tovarlar (xizmatlar) nomi',
  no: 'No',
  content: 'Mazmuni',
  delete: 'Oʻchirish',
  oldInvoice: " Eski hisob-faktura ma'lumotlari",
  idInvoice: 'Eski hisob-fakturaning identifikatori',
  oldInvoiceNumber: 'Eski hisob-faktura raqami',
  oldInvoiceDate: 'Eski hisob-fakturaning sanasi',
  invoiceType: 'Hisob-faktura turi',
  unilateralInvoice: 'Bir tomonlama hisob-faktura',
  unilateralDocument: 'Bir tomonlama hujjat turi',
  exciseInvoice: 'Aksiz solig‘i bo‘yicha hisobvaraq-faktura',
  empowermentNomber: 'Ishonchnoma raqami',
  empowermentData: 'Ishonchnoma berilgan sana',
  tinFace: 'TIN masul shaxs',
  fioFace: 'F.I.O masul shaxs',
  documentData: " Hujjat ma'lumotlari",
  denomination: 'Nom',
  contractNumber: 'Shartnoma raqami',
  placeConclusion: 'Tuzilgan joyi',
  dataConclusion: 'Tuzilgan sana',
  validUntil: 'Amal qilish muddati',
  partnerDate: "Hamkor ma'lumotlari",
  removePartner: 'Hamkorni o‘chirish',
  addPartner: 'Hamkorni qo‘shish',
  createAct: 'Yangi bajarilgan ishlar dalolatnomasini yaratish',
  documentText: 'Hujjat matni',
  textAct: 'Dalolatnoma matni',
  yourData: "Sizning ma'lumotlaringiz",
  company: 'Korxona',
  addNewProduct: " Yangi mahsulot qo'shing",
  withoutVat: 'QQSsiz',
  act: 'Dalolatnoma №',
  documentDate: 'Hujjat sanasi',
  contractDate: 'Shartnoma sanasi',
  portnerCompany: 'Hamkor kompaniya',
  outgoing: 'Chiqish',
  inbox: 'Kirish',
  confidant: 'Ishonchli vakil',
  listDocumnt: "Hujjatlar ro'yxati",
  createNewDocument: 'Yangi hujjat yarating',
  factura: 'Hisob faktura',
  ACT: 'AKT',
  empowermentTwo: 'Ishonchnoma',
  setting: 'Sozlamalar',
  kiberVersion:
    '«KIBER.UZ» elektron hujjat almashinuv tizimi uchun dasturiy ta’minot',
  version: '(1.0-versiya)',
  companyName: "TESLAPRO MCHJ, O'zbekiston Respublikasi",
  exit: 'Chiqish',
  startTime: 'Boshlanish vaqti',
  endTime: 'Tugash vaqti',
  canceledDocumnet: 'Siz bu hujjatni bekor qildingiz',
  acceptedDocument: 'Siz ushbu hujjatni qabul qildingiz',
  rejectedDocument: 'Siz bu hujjatni rad etdingiz',
  savedDacument: 'Siz bu hujjatni saqladingiz',
  texture: 'Faktura',
  download: ' Yuklab olish PDF',
  certificateCompletion: 'Bajarilgan ishlar haqida AKT',
  invoice: 'Hisob-faktura',
  rejectionDocument: 'Bu hujjatni rad etishni tasdiqlaysizmi?',
  receiptDocument: 'Bu hujjatni qabul qilishni tasdiqlaysizmi?',
  enterReason: 'Sababini kiriting',
  cancellation: 'Bekor qilish',
  confirmation: 'Tasdiqlash',
  notRegistered: "Siz sistemada ro'yxatdan o'tmagansiz",
  dataIncorrectly: "Malumotlar noto'g'ri kiritilgan",
  documentSuccessfully: 'Hujjat muvoffaqiyatli yaratildi',
  language: 'Til',
  languages: 'Uz',
  uniqueTariffs: 'Kiberdan noyob tariflar:',
  dock: '/ dok.',
  som: "so'm",
  sendUp: 'Oyiga 1000 tagacha hujjat yuboring',
  ReceivingDocument: 'Hujjatlarni bepul qabul qilish',
  buy: 'Sotib olish',
  withPassword: 'Parol bilan',
  iAgree: 'Men shartlarga roziman',
  publicOffer: 'ommaviy taklif',
  register: 'Ro`yxatdan o`tish',
  noDocument: "Hujjat yo'q",
  header: 'Sarlavha',
  text: 'Matn',
  createPower: 'Yangi ishonchnoma yarating',
  dateIssue: 'Berilgan sana',
  director: 'Direktor',
  accountant: 'Buxgalter',
  jopTitle: 'Lavozim',
  seriesPassword: 'Pasport seriya va raqami',
  issueBy: 'Kim tomonidan berilgan',
  createInvoice: 'Yangi hisobvaraq-fakturani yaratish',
  invoiceNumber: 'Hisob-faktura raqami',
  vatRegisttation: 'QQS to‘lovchisi sifatida hisobga qo‘yilganlik kodi',
  goodReleased: 'Tovar chiqarildi',
  enterPassword: 'Kalit uchun parolni kiriting',
  free: 'Bepul',
  freePlan: 'Tarif birinchisiga 2 oy bepul',
  appliesAll:
    'Joriy oy uchun 100 dan ortiq chiquvchi hujjatlar bilan barcha yuborilgan hujjatlarga taalluqlidir',
  allDocument: 'Barcha chiquvchi hujjatlar hisobga olinadi',
  documentSending:
    'Oy davomida chiquvchi hujjatlar coni 100 tadan kam bo`lsa, tarif avtomat ravishda `Базовый` ga o`zgaradi',
  sendPurchase:
    'Cheksiz miqdorda xarid so`rovlar va sotuv takliflarini jonating',
  getAccess:
    'Didox tizimida 250 mingdan ortiq foydalanuvchilar orasida yetkazib beruvchi va mojozlarni toping',
  changePassword: 'Kalit so`zni o`zgartirish',
  close: 'Yopish',
  packageCode: 'Paket kodi',
  back: 'Orqaga',
  privilege: 'Imtiyoz',
};
