import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import FTextField from "../../../components/FormElements/FTextField2";

// @ts-ignore
const SellerForm = ({ formik, title, showFirst, showSecond, checkboxTitle }) => {
  const { t } = useTranslation();
  return (
    <div style={{ flex: 1 }}>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Typography color="secondary" variant="h5" className="card-title">
          {title}
        </Typography>
        <div style={{ display: "flex", width: "100%", marginLeft: "10px" }}>
          {showFirst && (<FormControlLabel
            sx={{ flex: "1" }}
            control={
              <Checkbox
                name="excise"
              />
            }
            label="Агент (доверенное лицо)"
          />)}
          {showSecond && <FormControlLabel
            sx={{ flex: "1" }}
            control={
              <Checkbox
                name="committent"
              />
            }
            label={checkboxTitle}
          />}
        </div>
      </Box>
      <div className="content">
        <Box sx={{ display: "flex", flexDirection: "row", gap: 1, marginTop: 1 }}>
          {/* @ts-ignore */}
          <FTextField
            label={t("tinPinfl")}
            name="sellerTin"
            fullWidth
            formik={formik}
          />
          {/* @ts-ignore */}
          <FTextField
            required
            label={t("name")}
            name="seller.name"
            formik={formik}
            fullWidth
          />
        </Box>
      </div>
    </div>
  );
};

export default SellerForm;
