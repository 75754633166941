import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import FTextField from "../../../components/FormElements/FTextField2";
import FDatePicker from "../../../components/FormElements/FDatePicker";

// @ts-ignore
const TransportType = ({ formik, title }) => {
  const { t } = useTranslation();
  return (
    <div style={{ flex: 1 }}>
      <Typography color="secondary" variant="h5" className="card-title">
        {title}
      </Typography>
      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <FormControlLabel
          control={
            <Checkbox
              name="excise"
            />
          }
          label="Aвтомобиль"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="excise"
            />
          }
          label="Железнодорожный"
        />
        <FormControlLabel
          sx={{ flex: "1" }}
          control={
            <Checkbox
              name="excise"
            />
          }
          label="Полуприцеп"
        />
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Box sx={{ display: "flex", gap: 1, marginTop: 1, flex: "1" }}>
          {/* @ts-ignore */}
          <FormControl style={{ width: "50%" }}>
            {/* @ts-ignore */}
            <InputLabel style={{ marginTop: -6 }} >
              {"Гос. номер автомобиля"}
            </InputLabel>
            <Select
              label={t("name")}
              size="small"
            ></Select>
          </FormControl>
          {/* @ts-ignore */}
          <FTextField
            formik={formik}
            label={"Модель авто"}
            required
          />
        </Box>
        <Box sx={{ display: "flex", gap: 1, marginTop: 1, flex: "1" }}>
          {/* @ts-ignore */}
          <FormControl style={{ width: "50%" }}>
            {/* @ts-ignore */}
            <InputLabel style={{ marginTop: -6 }} >
              {"Гос. номер автомобиля"}
            </InputLabel>
            <Select
              label={t("name")}
              size="small"
            ></Select>
          </FormControl>
          {/* @ts-ignore */}
          <FTextField
            formik={formik}
            label={"Модель авто"}
            required
            disabled
          />
        </Box>
      </div>
    </div>
  );
};

export default TransportType;
