import {
  Box,
  Button,
  Card,
  experimentalStyled,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header";
import { showAlert } from "../../redux/thunks/alert.thunk";
import {
  createFacturaDoc,
  saveDocToDraft,
} from "../../redux/thunks/docs.thunk";
import draftService from "../../services/draftService";
import userService from "../../services/userService";
import "./style.scss";
import FacturaType from "./forms/FacturaType";
import DocForm from "./forms/DocForm";
import SellerForm from "./forms/SellerForm";
import BuyerForm from "./forms/BuyerForm";
import TransportType from "./forms/TransportType";
import ProductList from "./forms/ProductList";
import QRCode from "react-qr-code";
import moment from "moment";
const StyledCard = experimentalStyled(Card)(({ theme }) => ({
  display: "inline-block",
  padding: 15,
  marginBottom: 20,
  width: "100%",
}));

const TtnCreate = () => {

  const history = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [showDoc, setShowDoc] = useState(false);
  // @ts-ignore
  const { title } = useSelector((state) => state.settings.lang);
  const [status, setStatus] = useState();
  const [suspend, setSuspend] = useState();

  type UserData = {
    person: {
      tin: string;
      shortName: string;
      account: string;
      mfo: string;
      address: string;
      oked: string;
      districtId: number;
      director: string;
      accountant: string;
    };
    taxGap: {
      taxGap: unknown;
    };
    vatReg: {
      data: {
        vatRegCode: string;
        status: number;
      };
    };
  };

  const userData = useSelector(
    (state: { auth: { userData: UserData } }) => state.auth.userData
  );
  const draftData = location?.state;

  const { t } = useTranslation();
  const [tempDisable, setTempDisable] = useState(true);
  console.log({ draftData });

  const onSubmit = (values: any) => {
    if (formik.values.facturaType === 4 && productList[0].origin === 0) {
      // @ts-ignore
      dispatch(showAlert("Нужно указать происхождение товара"));
    } else {
      console.log("he");
      // @ts-ignore
      dispatch(createFacturaDoc(values, productList));
    }
  };

  const formik = useFormik({
    initialValues: draftData ?? {
      sellerTin: userData.person.tin,
      seller: {
        account: userData.person.account,
        bankId: userData.person.mfo,
        address: userData.person.address,
        mobile: "",
        workPhone: "",
        oked: userData.person.oked,
        districtId: userData.person.districtId,
        director: userData.person.director,
        accountant: userData.person.accountant,
        branchCode: "",
        branchName: "",
        vatRegCode: userData.vatReg.data.vatRegCode,
        vatRegStatus: userData.vatReg.data.status,
      },
    },
    onSubmit,
  });
  const [productList, setProductList] = useState(
    draftData?.productList.products ?? [
      {
        id: 1,
        ordNo: 1,
        committentName: "",
        committentTin: "",
        committentVatRegCode: "",
        catalogCode: "",
        catalogName: "",
        barcode: "",
        lgotaId: null,
        serial: "",
        baseSumma: 0,
        profitRate: 0,
        count: 0,
        summa: 0,
        exciseRate: 0,
        exciseSum: 0,
        deliverySum: 0,
        lgotaVatSum: 0,
        packageCode: "",
        deliverySumWithVat: 0,
        packageName: "",
        origin: 0,
        withoutVat: true, // true - если “Без НДС”
      },
    ]
  );

  const searchBuyer = (tin: any) => {
    const token = localStorage.getItem("token");
    setTempDisable(true);
    userService
      // @ts-ignore
      .searchUser(tin, JSON.parse(token))
      .then((res) => {
        const computedBuyer = {
          ...formik.values.buyer,
          // @ts-ignore
          account: res.person.account,
          // @ts-ignore
          bankId: res.person.mfo,
          // @ts-ignore
          address: res.person.address,
          // @ts-ignore
          oked: res.person.oked,
          // @ts-ignore
          districtId: res.person.districtId,
          // @ts-ignore
          director: res.person.director,
          // @ts-ignore
          accountant: res.person.accountant,
          // @ts-ignore
          vatRegCode: res.vatReg.data.vatRegCode,
          // @ts-ignore
          vatRegStatus: res.vatReg.data.status,
        };
        formik.setFieldValue("buyer", computedBuyer);
        // @ts-ignore
        setStatus(res.vatReg.data.active);
        // @ts-ignore
        setSuspend(res.vatReg.data.suspended);
      });

    setTempDisable(false);
  };
  // @ts-ignore
  useEffect(() => {
    const buyerTin = formik.values.buyerTin;
    if (buyerTin?.length !== 9 && buyerTin?.length !== 14) {
      setTempDisable(true);
    } else {
      searchBuyer(buyerTin);
      formik.setFieldValue("productList.hasLgota", true);
    }
  }, [formik.values.buyerTin]);

  const qrCodeValue = `https://my3.soliq.uz/roaming-viewer/ru/document?id=${params.id}&filetype=1&tin=${location?.state}`;

  const saveDocument = () => {
    setShowDoc(true);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Header title={t("createTtn")}>
        <Button
          variant="contained"
          color="error"
        >
          {t("back")}
        </Button>
        <Button variant="contained" color="warning" onClick={saveDocument}>
          {t("seve")}
        </Button>
        <Button variant="contained">
          {t("subscribe")}
        </Button>
      </Header>

      <div className="TtnCreate">
        <StyledCard elevation={12}>
          <FacturaType formik={formik} />
          <DocForm formik={formik} />
        </StyledCard>
        <Box sx={{ display: 'flex', flexDirection: "row", gap: 2 }}>
          <StyledCard elevation={12}>
            <SellerForm showFirst showSecond title={"Грузоотправитель"} checkboxTitle={"Одновременно и экспедитор"} formik={formik} />
          </StyledCard>
          <StyledCard elevation={12}>
            {/* @ts-ignore */}
            <SellerForm showSecond title={"Грузоотправитель"} checkboxTitle={"Одновременно и экспедитор"} formik={""} />
          </StyledCard>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: "row", gap: 2 }}>
          <StyledCard elevation={12}>
            {/* @ts-ignore */}
            <SellerForm showSecond title={"Экспедитор"} checkboxTitle={"Экспедитор одновреммено является грузоперевозчиком"} formik={""} />
          </StyledCard>
          <StyledCard elevation={12}>
            {/* @ts-ignore */}
            <SellerForm title={"Грузоотправитель"} checkboxTitle={"Одновременно и экспедитор"} formik={""} />
          </StyledCard>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: "row", gap: 2 }}>
          <StyledCard elevation={12}>
            {/* @ts-ignore */}
            <BuyerForm title={"Клиент"} formik={""} />
          </StyledCard>
          <StyledCard elevation={12}>
            {/* @ts-ignore */}
            <BuyerForm title={"Заказчик"} formik={""} />
          </StyledCard>
        </Box>
        <StyledCard elevation={12}>
          {/* @ts-ignore */}
          <TransportType title={"Тип транспорта"} formik={""} />
        </StyledCard>
        <Box sx={{ display: 'flex', flexDirection: "row", gap: 2 }}>
          <StyledCard elevation={12}>
            {/* @ts-ignore */}
            <SellerForm showSecond title={"Водитель"} checkboxTitle={"Водитель также является ответственным лицом, доставляющим груз"} formik={""} />
          </StyledCard>
          <StyledCard elevation={12}>
            {/* @ts-ignore */}
            <SellerForm title={"Ответственное лицо, доставляющий груз"} formik={""} />
          </StyledCard>
        </Box>
        <StyledCard elevation={12}>
          {/* @ts-ignore */}
          <ProductList
            productList={productList}
            setProductList={setProductList}
            formik={formik}
          />
        </StyledCard>
        <StyledCard elevation={12}>

        </StyledCard>
        {showDoc && (
          <div
            style={{
              position: "absolute",
              background: "white",
              top: 0,
              left: 0,
              height: "100%",
              width: "100%",
            }}
          >
            {/* @ts-ignore */}
            <Header

              title={`ТТН № ${formik.values?.contractDoc?.contractNo || "---"
                } от `}
            ></Header>

            <div style={{ padding: "20px" }}>
              <StyledCard elevation={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", gridGap: "10px" }}>
                    {/* @ts-ignore */}
                    <a download target="_blank" rel="noreferrer">
                      <Button
                        variant="contained"
                        color="warning"
                        onClick={() => setShowDoc(false)}
                      >
                        Редактировать
                      </Button>
                    </a>
                    <>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => formik.handleSubmit}
                        type="submit"
                      >
                        Подписать
                      </Button>
                    </>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => {
                        setShowDoc(false);
                        navigate("/");
                      }}
                    >
                      Выйти
                    </Button>
                  </div>
                </div>
              </StyledCard>
              <div className="containerFactura">
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: " 100%" }}>
                  <div className="old-factura-block" >
                    к ЭСФ №{" "}
                  </div>
                  <QRCode
                    style={{
                      height: '100px',
                      maxWidth: '100px',
                      width: '100px',
                    }}
                    value={qrCodeValue}
                    viewBox={`0 0 256 256`}
                  />
                </Box>
                <div className="title">
                  <p>ТТН (новый)</p>
                  <p>
                    №
                  </p>
                  <p>
                    к договору №
                  </p>
                </div>

                <div className="info-block">
                  <div className="seller-side">
                    <div className="label">Поставщик:</div>
                    <div className="value"> ""</div>

                    <div className="label">Адрес:</div>
                    <div className="value"></div>

                    <div className="label">
                      Идентификационный номер поставщика (ИНН):
                    </div>
                    <div className="value">{formik.values.sellerTin}</div>

                    <div className="label">
                      Регистрационный код плательщика НДС:
                    </div>
                    <div className="value">

                    </div>

                    <div className="label">Р/С:</div>
                    <div className="value"></div>

                    <div className="label">МФО:</div>
                    <div className="value"></div>
                  </div>

                  <div className="buyer-side">
                    <div className="label">Покупатель:</div>
                    <div className="value">""</div>

                    <div className="label">Адрес:</div>
                    <div className="value"></div>

                    <div className="label">
                      Идентификационный номер поставщика (ИНН):
                    </div>
                    <div className="value">{formik.values.buyerTin}</div>

                    <div className="label">
                      Регистрационный код плательщика НДС:
                    </div>
                    <div className="value">

                    </div>

                    <div className="label">Р/С:</div>
                    <div className="value"></div>

                    <div className="label">МФО: </div>
                    <div className="value"></div>
                  </div>

                  <div className="single-side">
                    <div className="single-side-type-block"></div>
                  </div>
                </div>
                {/* @ts-ignore */}
                {productList.map((item) => (
                  <table
                    cellSpacing="0"
                    style={{ width: "100%" }}
                    key={item.ordNo}
                  >
                    <thead>
                      <tr>
                        <th>№</th>
                        <th>
                          ИКПУ и наименование товаров (работ, услуг)
                        </th>
                        <th>Описание товаров (работ, услуг)</th>
                        <th>Единица измерения</th>
                        <th>Количество</th>
                        <th>Цена</th>
                        <th>Сумма (без НДС)</th>
                        <th>Масса (ТН)</th>
                        <th>Брутто (ТН)</th>
                        <th>Нетто (ТН)*</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>{item.ordNo}</td>
                        <td>
                          {item.catalogCode} -- {item.catalogName}
                        </td>
                        <td>{item.barCode}</td>
                        <td>{item.measureId}</td>
                        <td>{item.count}</td>
                        <td>{item.baseSumma}</td>
                        <td>{item.deliverySum}</td>
                        <td>{item.vatRate}</td>
                        <td>{item.deliverySumWithVat}</td>
                      </tr>

                      <tr>
                        <td className="bold" colSpan={8}>
                          Итого
                        </td>
                        <td className="center bold">

                        </td>
                      </tr>
                    </tbody>
                  </table>
                ))}
                <div className="humans-wrapper">
                  <div className="humans">
                    <p>
                      <b>Руководитель: </b>
                    </p>
                    <p>
                      <b>
                        Главный бухгалтер: {formik.values.seller.accountant}
                      </b>
                    </p>
                    <p>
                      <b>
                        Товар отпустил:{" "}
                      </b>
                    </p>
                  </div>
                  <div className="humans">
                    <p>
                      <b>Руководитель: </b>
                    </p>
                    <p>
                      <b>Главный бухгалтер: </b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

export default TtnCreate;
