import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import FDatePicker from "../../../components/FormElements/FDatePicker";
import FTextField from "../../../components/FormElements/FTextField2";

// @ts-ignore
const DocForm = ({ formik }) => {
  const { t } = useTranslation();
  return (
    <>
      <Box sx={{ flexDirection: "column", marginTop: 4, marginLeft: 2, display: 'flex' }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          {/* @ts-ignore */}
          <FTextField
            formik={formik}
            label={"Номер товарно-транспортной накладной"}
            required
          />
          {/* @ts-ignore */}
          <FDatePicker
            label={t("documentDate")}
            required
            formik={formik}
          />
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          {/* @ts-ignore */}
          <FTextField
            formik={formik}
            name="contractDoc.contractNo"
            label={t("contractNumber")}
            required
          />
          {/* @ts-ignore */}
          <FDatePicker
            formik={formik}
            label={t("contractDate")}
            name="contractDoc.contractDate"
            required
          />
        </Box>
      </Box>
    </>
  );
};

export default DocForm;
