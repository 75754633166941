import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import FTextField from "../../../components/FormElements/FTextField2";
import FDatePicker from "../../../components/FormElements/FDatePicker";

// @ts-ignore
const BuyerForm = ({ formik, title, showFirst, showSecond, checkboxTitle }) => {
  const { t } = useTranslation();
  return (
    <div style={{ flex: 1 }}>
      <Typography color="secondary" variant="h5" className="card-title">
        {title}
      </Typography>
      <div className="content">
        <Box sx={{ display: "flex", gap: 1, marginTop: 1 }}>
          {/* @ts-ignore */}
          <FormControl style={{ width: "100%" }}>
            {/* @ts-ignore */}
            <InputLabel style={{ marginTop: -6 }} >
              {t("tinPinfl")}
            </InputLabel>
            <Select
              // @ts-ignore
              label={t("name")}
              size="small"
            ></Select>
          </FormControl>
        </Box>
        <Box sx={{ display: 'flex', gap: 2, marginTop: 2 }}>
          {/* @ts-ignore */}
          <FTextField
            formik={formik}
            label={t("contractNumber")}
            required
            disabled
          />
          {/* @ts-ignore */}
          <FDatePicker
            formik={formik}
            label={t("contractDate")}
            required
            disabled
          />
        </Box>
      </div>
    </div>
  );
};

export default BuyerForm;
