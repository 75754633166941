import { Switch, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import FSelect from "../../../components/FormElements/FSelect";
import FSwitch from "../../../components/FormElements/FSwitch";

// @ts-ignore
const FacturaType = ({ formik }) => {
  const { t } = useTranslation();
  const facturaTypes = [
    {
      label: t("Стандартный"),
      value: 0,
    },
    {
      label: t("Дополнительный"),
      value: 1,
    },
    {
      label: t("Исправленный"),
      value: 2,
    },
  ];

  const singleSidedTypes = [
    {
      label: t("phisFace"),
      value: 1,
    },
    {
      label: t("eksportServiceFrom"),
      value: 2,
    },
    {
      label: t("importService"),
      value: 3,
    },
    {
      label: t("financeService"),
      value: 4,
    },
    {
      label: t("belowMarket"),
      value: 5,
    },
    {
      label: t("belowTax"),
      value: 6,
    },
    {
      label: t("eskportServiceIn"),
      value: 7,
    },
    {
      label: t("realizationGov"),
      value: 8,
    },
    {
      label: t("destroyAksiz"),
      value: 9,
    },
    {
      label: t("otherIncome"),
      value: 10,
    },
    {
      label: t("recycleAksiz"),
      value: 11,
    },
    {
      label: t("reuseAksiz"),
      value: 12,
    },
    {
      label: t("priceDifferenceAksiz"),
      value: 13,
    },
  ];

  return (
    <>
      <Typography
        onClick={() => console.log("VALUES ===> ", formik.values)}
        color="secondary"
        variant="h5"
        className="card-title"
      >
        Тип ТТН
      </Typography>
      <div className="form-block">
        <FSelect
          label={"Тип ТТН"}
          width={"300px"}
          formik={formik}
          name="facturaType"
          options={facturaTypes}
        />
      </div>
      <Typography
        color="black"
        variant="h4"
        className="card-title-box"
      >
        Тип перевозки
      </Typography>
      <div className="" style={{
        display: "flex",
        flexDirection: "row"
      }}>
        {/* @ts-ignore */}
        <FSwitch
          label={"От склада до склада"}
          formik={formik}
          name="productList.hasExcise"
        />
        {/* @ts-ignore */}
        <FSwitch
          label={"От продавца до покупателя"}
          formik={formik}
          name="productList.hasExcise"
        />
        {/* @ts-ignore */}
        <FSwitch
          label={"Другие"}
          formik={formik}
          name="productList.hasExcise"
        />
      </div>
    </>
  );
};

export default FacturaType;
